
import solutionData from './indexData'
import { ref, onMounted, onBeforeUnmount, defineComponent, VueElement } from 'vue';
import headerTitle from '../productCenter/headerTitle'

interface navigation {
    id?: string
    index?: number
}


export default defineComponent({
    components: { headerTitle },
    data() {
        return {
            navigationShow: false,
            product: '',
            productData: solutionData,
            scrolloff: true,
            navigatTimeout: 0
        }
    },
    watch: {
        $route(newValue, oldValue) {
            let that = this
            if (!newValue.query.id) return

            setTimeout(() => {
                this.onNavigation({ id: this.$route.query.id as string })
            }, 400)
        }
    },
    mounted() {

        if (this.$route.query.id) {
            setTimeout(() => {
                this.onNavigation({ id: this.$route.query.id as string })
            }, 500)
        }


        window.onscroll = () => {

            if (this.scrolloff) {
                const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;


                if (scrollTop - 560 > 0) {
                    this.navigationShow = true
                } else {
                    this.navigationShow = false
                }


                for (let index in this.productData) {
                    const solution = document.getElementById('product' + index)
                    const offsetTop = (solution?.offsetTop as number) + (solution?.offsetHeight as number)

                    if (scrollTop + 50 > (solution?.offsetTop as number) && scrollTop + 100 < offsetTop) {
                        this.product = "#product" + index
                        // location.hash="#product"+index
                        break;
                    }
                }
            }
        }
    },
    methods: {
        onNavigation({ id, index }: navigation) {
            this.product = id ? id : "#product" + index
            this.scrolloff = false
            let domScr = document.querySelector(this.product)
            domScr && domScr.scrollIntoView({
                behavior: 'smooth'
            })
            if (this.navigatTimeout) {
                clearTimeout(this.navigatTimeout)
            }
            this.navigatTimeout = setTimeout(() => {
                this.scrolloff = true
                this.navigationShow = true
            }, 1000)
        }
    },
    beforeUnmount() {
        window.onscroll = null
    }
});




