/*
 * @Author: your name
 * @Date: 2022-01-10 14:39:02
 * @LastEditTime: 2022-12-12 14:05:40
 * @LastEditors: HuYuliu 10097355522qq.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \gaoshuofficialwebsite\src\views\companyProfile\index.tsx
 */
import { defineComponent, ref, onMounted ,onBeforeUnmount} from 'vue';
import '@/views/productCenter/index.scss'
import { useRouter } from 'vue-router'

/**
 * 标题
 */
 export default defineComponent({
    props: {
        title: {
            type: String
        },
        conten: {
            type: String
        },
        right: {
            type: Boolean,
            default: () => {
                return false
            }
        }
    },
    setup(props, cex) {
        return () => (
            <div class="productCenter-item-header">
                <div style={{ justifyContent: props.right ? 'flex-end' : '' }} class="productCenter-item-header-title">
                    <div>
                        <p>{props.title}</p>
                        <span>{props.conten}</span>
                    </div>
                </div>
            </div>
        )

    },
});
